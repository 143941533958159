import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до тенісного клубу Arcadia
			</title>
			<meta name={"description"} content={"Там, де пристрасть зустрічається з грою - Arcadia, ваш тенісний притулок"} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до тенісного клубу Arcadia"} />
			<meta property={"og:description"} content={"Там, де пристрасть зустрічається з грою - Arcadia, ваш тенісний притулок"} />
			<meta property={"og:image"} content={"https://luminarixgold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luminarixgold.com/img/197704602.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://luminarixgold.com/img/197704602.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="50px 50px 50px 50px"
					padding="80px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						тенісний клуб Arcadia{"\n "}
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197" lg-margin="0px 0px 30px 0px">
					У тенісному клубі Arcadia ми розпалюємо вашу пристрасть до тенісу, пропонуючи привабливу атмосферу для гравців різного віку та рівня майстерності. Оточені безтурботними пейзажами, наші корти є маяком для тих, хто прагне вдосконалити свою гру, насолодитися змагальними матчами або знайти радість у простому задоволенні від удару по м'ячу. Arcadia - це не просто клуб, це спільнота, де кожна подача і кожен удар має значення.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					/>
				</Box>
				<Image
					src="https://luminarixgold.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 30px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Чому варто приєднатися до тенісного клубу Arcadia?
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Найкращі ігрові покриття
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Грайте на бездоганно доглянутих кортах, спроектованих таким чином, щоб запропонувати як виклик, так і задоволення для гравців усіх рівнів.
						</Text>
					</Box>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Активна спільнота
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Серцем Arcadia є наша спільнота. Беріть участь у соціальних турнірах, клубних заходах та знайомтеся з різноманітною групою людей, які поділяють вашу любов до тенісу.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Індивідуальні тренування
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Скористайтеся перевагами індивідуальних тренувань від нашої команди кваліфікованих професіоналів, готових підняти вашу гру на новий рівень.
						</Text>
					</Box>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Зосередьтеся на фітнесі
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Доповніть свою гру в теніс нашими фітнес-залами та програмами, спрямованими на покращення сили, спритності та загального стану здоров'я.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="48px 0 48px 0" md-padding="36px 0 36px 0" quarkly-title="Images-19">
			<Override slot="SectionContent" width="100%" max-width="100%" />
			<Image src="https://luminarixgold.com/img/2.jpg" object-fit="cover" />
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});